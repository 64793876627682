.app-select-display-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-select-clear {
    display: none;
  }

  .ant-select-selection-item {
    display: none;
  }

  .ant-select-selection-overflow {
    .ant-select-selection-overflow-item {
      display: none;
    }

    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
      display: unset;
    }
  }

  .list-wrapper {
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    gap: 12px;

    .item-wrapper {
      padding: 8px 8px;
      border-radius: 4px;
      border: 1px solid rgb(220, 220, 228);
    }
  }
}

@primary-color: #0A8FDC;