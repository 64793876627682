.container-avatar {
  position: relative;

  .close {
    position: absolute;
    top: -48px;
    right: 0;
    font-size: 20px;
    color: rgb(249, 72, 72);
    cursor: pointer;
    z-index: 10000000000;
  }
}

.ant-upload-select-picture {
  width: 100%;
}

.avatar {
  object-fit: cover;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  // background-color: #f8f8f8;
}

.imageCustom {
  width: 120px;
}

.bannerCustom {
  min-width: 200px;
  width: 100%;
}

a[title="Preview file"] {
  display: inline-block;
  pointer-events: all !important;
  cursor: pointer !important;
  opacity: 100 !important;
  color: rgba(255, 255, 255, 0.65) !important;
  height: 22px !important;
  border-radius: 4px;
}

a[title="Preview file"]:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.06) !important;
}
@primary-color: #0A8FDC;