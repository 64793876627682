.quill {
  &.ql-error {
    .ql-toolbar {
      border: 1px solid red;
    }

    .ql-container {
      border-left: 1px solid red;
      border-right: 1px solid red;
      border-bottom: 1px solid red;
    }
  }

  .ql-toolbar {
    border-radius: 4px 4px 0 0;
  }

  .ql-container {
    border-radius: 0 0 4px 4px;
    min-height: 150px;
  }
}
@primary-color: #0A8FDC;