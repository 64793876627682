.config-container {
  background-color: white;
  max-height: 400px;
  width: 150px;
  padding: 10px;
  border: 1px solid #EDEDED;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.submit-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.date-picker,
.time-picker {
  width: 100%;
}

.ant-col-24 {
  label {
    height: auto;
  }
}

.title {
  margin-bottom: 8px !important;
}

.content {
  margin-bottom: 0 !important;
  color: #5C5C5C;
}

.row-data:has(.ant-empty) {
  justify-content: center;
}

//drawer
.input-search {
  width: 300px;
  margin-bottom: 20px;
}

//preview
.preview_email_container {
  background: linear-gradient(180deg, #AA372B 0%, #FA6152 100%);
  padding: 20px;

  .logo_wrapper_parent {
    display: flex;
    justify-content: center;

    .logo_wrapper {
      border-radius: 12px 12px 0 0;
      background-color: white;
      height: 40px;
      padding: 8px 24px;
    }
  }

  .preview_thread_container {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px 24px 0 0;

    margin-top: 20px;
    padding: 20px 0 20px 0;

    .button_email {
      background-color: #FA6152;
      color: white;
      border-radius: 8px;
      border: none;
      padding-left: 30px;
      padding-right: 30px;

      font-style: normal;
      font-weight: 700;
      font-size: 18px;
    }
  }

  .footer_email {
    padding: 32px 36px;
    display: flex;

    .footer_content {
      display: flex;
      flex: 4;
      color: white;
      flex-direction: column;
      font-size: 12px;
      line-height: 16px;
      gap: 5px;

      .icon-wraper {
        font-size: 18px;
        display: flex;
        gap: 10px;
      }
    }

    .footer_button {
      display: flex;
      flex: 6;
      background-color: #B64035;
      border-radius: 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .dwn_button {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 0 25px;
      }
    }
  }
}

.preview_post_container {
  border-radius: 8px;
  border: 1px solid #EDEDED;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.avatar_preview {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.header_post_preview {
  display: flex;
  padding: 0 15px;
  gap: 4px;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #EDEDED;
}

.content_post_preview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 15px;

  .title_preview {
    font-weight: 600;
    font-size: larger;
  }

  .image_preview {
    position: relative;
    width: 100%;

    .footer_preview {
      display: flex;
      justify-content: space-between;
      padding: 15px 10px;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(4px);
      position: absolute;

      width: 100%;
      bottom: 0;
      color: white;

      .footer_text {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.preview_noti_container {
  background: #FFF1EF;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;

  .top_noti_content {
    display: flex;
    width: 100%;
    gap: 10px;

    .left_noti_preview {
      flex: 7;
      gap: 15px;
      display: flex;
      flex-direction: column;

      .header_noti_preview {
        display: flex;
        align-items: center;
        padding: 0;
        gap: 4px;
      }

      .content_noti_preview {
        .title_preview {
          font-weight: 600;
          font-size: larger;
        }

        .content_noti {
          max-height: 4em;
          overflow: hidden;
          position: relative;
        }

        .readmore {
          color: #F04F47;
        }
      }
    }

    .right_noti_preview {
      flex: 3;
    }
  }
}

.tab-dropdown {
  z-index: 2000;
}
@primary-color: #0A8FDC;